@import 'src/variables.scss';

.container {
  min-width: 251px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-right: rgb(207, 207, 207) 1px solid;
  padding-top: 50px;
  background-color: white;
  // min-height: 980px;
  height: 100%;
}

NavItem, NavLink {
  margin: 0;
  padding: 0;
}

.linkStyle {
  display: flex;
  justify-content: left;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: 1em;
  font-size: 16px;
  letter-spacing: 2px;
  color: #AAAAAA;
  width: 100%;
  margin-left: 27px;
}
.linkStyleActive {
  display: flex;
  justify-content: left;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: 1em;
  font-size: 16px;
  letter-spacing: 2px;
  color: $black;
  width: 100%;
  margin-left: 27px;
}

.linkStyle:hover{
  color: $black;
}

.linkStyleActive:hover{
  color: $black;
}

.menuIcon {
  width: 40px;
  height: 40px;
  float: left;
  margin-top: -6px;
  margin-right: 15px;
}

.menuIconActive {
  background-image: url(../../../assets/images/ellipse-active.png);
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
  float: left;
  margin-top: -9px;
  margin-right: 15px;
}

.centeredImage {
  position: relative;
  left:50%;
  top:50%;
  transform: translate(-50%, -50%);
}

.centeredImageCloud {
  margin: 10px;
}

.navClass {
  display: flex;
  justify-content: top;
  margin: 0;
  padding: 0;
  flex:18 auto;
}

.navItemClass{
  display: flex;
  justify-content: left;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.iconPosition{
  display: block;
  margin-right: 10px;
  margin-left: 20px;
}

.cloudButtonContainer {
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  justify-content: center;
  align-items: center;
  align-items: bottom;

}

.wrapDownloadPlayer {
  padding: 16px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 18px;
}

a {
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  color: $primaryColor;
  display: block;
}

.pass {
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 17px;
  padding: 16px 0;
  color: #000000;
  font-family: 'Nunito Sans';
}

.passcodeStyle {
  color: #EC812B;
  margin-left: 0.5em;
  margin-right: 10px;
}

.passcodeGenerateIcon {
  position: relative;
  top: -1px;
  cursor: pointer;
}

.downLoadButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #EFEFF4;
  border-radius: 5px;
  padding: 3px 0 3px 16px;
}

.downLoadButton:not(:last-child) {
  margin-bottom: 16px;
}

.buttonTitle {
  font-family: Avenir;
  color: #8A8A8F;
  margin-left: 10px;
}

.platform {
  color: #000000;
}

.cloudButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 0;
}

.containerDesktopApp {
  margin: 40px 20px;
  display: flex;
  flex-direction: column;
}