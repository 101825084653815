/*List Styles*/

.pinned-list {
  position: relative;
  height: 370px;
  overflow: auto;
  width: auto;
}

.user-list {
  width: 100%;
  padding: 20px 15px 10px;

  &.card {
    padding-left: 30px;

    @media screen and (max-width: 575px) {
      padding-left: 15px;
    }
  }

  & .user-avatar {
    margin-right: 20px;
    margin-bottom: 10px;
    @extend %size-50;
  }

  & h3 {
    font-weight: 700;
    margin-bottom: 8px;
  }

  & a[class*="meta-"] i {
    margin-right: 6px;
  }

  & a[class*="meta-"]:hover {
    text-decoration: none;
  }

  & a[class*="meta-"] {
    display: inline-block;
    color: $body-color;
    font-size: $font-size-sm;
    font-weight: 700;
  }
}

.user-list {
  & .img-section {
    width: 230px;
    @media screen and (max-width: 649px) {
      width: 100%;
    }
  }

  & .description {
    width: calc(100% - 300px);
    @media screen and (max-width: 649px) {
      width: 100%;
    }
  }

  & .jr-btn-list {
    & li:not(:last-child) {
      margin-right: 10px;
    }

    & .btn-light {
      background-color: darken(map_get($gray, lighten-2), 5%);
      border-color: darken(map_get($gray, lighten-2), 5%);
      color: $body-color;
      font-weight: 600;

      &:hover,
      &:focus,
      &:active {
        background-color: darken(map_get($gray, lighten-2), 10%);
        border-color: darken(map_get($gray, lighten-2), 10%);
      }
    }

    &.badge-list {
      & li:not(:last-child) {
        margin-right: 0;
      }
    }
  }
}

.list-inline {
  margin-left: -5px;
  margin-right: -5px;
  & li {
    padding: 0 5px;
    display: inline-block;

    &.media {
      display: flex;
    }
  }
}

.card-strip {
  border-bottom: solid 1px $gray-300;
  margin-bottom: 10px;
}

.jr-mbtn-list {
  margin-left: -20px;
}

.img-res {

    max-width: 100%;
    height: auto;

}