@import "src/variables";

.PaginationList{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  position: relative;
  width: 100%;
}

li {
  list-style-type: none;
}

.goRight{

  font-size: 22px;
  font-weight:bold;
  cursor:pointer;
  color: $primaryColor;
  margin-left: 5px;

  &__disabled{
    color: grey;
    font-size: 22px;
    font-weight:bold;
    margin-left: 5px;
  }

  

}

.goLeft{

  font-size: 22px;
  cursor:pointer;
  color: $primaryColor;
  font-weight:bold;
  margin-right: 5px;

  &__disabled{
    color: grey;
    font-size: 22px;
    font-weight:bold;
    margin-right: 5px;
  }

  

}

input{
  border:0;
  outline: 0;
  background: white;
  width: 20px;
  text-align: center;
  padding-bottom: -30px;

  &:focus{
    outline: none!important;
  }

}

