@import 'src/variables.scss';

.mainSvg {
  height: 100%;
}

.activeFillColor {
  fill: $primaryColor;
}

.defaultFillColor {
  fill: $gray;
}

.activeStrokeColor {
  stroke: $primaryColor;
}

.defaultStrokeColor {
  stroke: $gray;
}