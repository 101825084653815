.container {
  min-width: 1107px;
  width: 100%;
  min-height: 74px;
  margin: 0;
  padding: 0;
}

.linkStyle {
  color: gray;
  font-weight:bold;
  font-size: 16px;
}

.navbarStyle {
  display: flex;
  // height: 74px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  color: #AAAAAA;
  // background-color: red;
  padding: 0;

}

.LogoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.LogoContainerUserIn {
  display: flex;
  align-items: flex-start;
}

.imgLogoutPosition {
  // margin-left: 13px;
  // margin-top: -2px;
}

.navbar {
  background-color: white;
  height: 74px;
  border-bottom: 1px solid #DCDCDC;
  padding-left: 30px;
  padding-right: 45px;
  // padding-bottom: 16px;

}

.passcodeStyle {
  color: black;
  margin-left: 0.5em;
}

.navItemMargin {
  // margin-left: 100px;
}

.imgSize {
  // width: 180px;

}


