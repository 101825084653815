@import 'src/variables.scss';

.mainSvg {
  height: 100%;
}

.activeColor {
  fill: $primaryColor;
}

.defaultColor {
  fill: $gray;
}