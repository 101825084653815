* {
  margin: 0;
  padding: 0;
}

.containerMain {
  box-sizing: border-box;
  min-height: 300px;
  height: 100vh;
  display: flex;
  flex-direction:column;
  position: relative;
  overflow: auto;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
   -khtml-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.contentWrapper {
  // background-color: yellow;
  box-sizing: border-box;
  height: 100%;
  min-height: 769px;
  display: flex;
  flex-direction:row;
  position: relative;
}

.content {
  box-sizing: border-box;
  width: 100%;
  min-height: 300px;
  min-width: 856px;
  // background-color: red;
}