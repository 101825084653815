@import 'src/variables.scss';

.wrapContainer {
  width: 100%;
}
.container {
  // width: 380px;
  width: 100%;
  max-width: 100%;
  display: flex;
}

.warning {
  display: block;
  margin-top: 5px;
  color: red;
  font-size: 14px;
}

.input {
  width: 100%;
  border: none;
  padding: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 20px;
  color: #000000;
  text-align: left!important;

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 17px;
    font-weight: normal;
    color: gainsboro;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 17px;
    font-weight: normal;
    color: gainsboro;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 17px;
    font-weight: normal;
    color: gainsboro;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    font-size: 17px;
    font-weight: normal;
    color: gainsboro;
  }
}

.fakeInput {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
  cursor: pointer;
  flex: 1 1 auto;
  width: 0;
  word-wrap: break-word;
}

.fakeInputIsNoWrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.fakeInputIsDisabled {
  cursor: default;
}

.visuallyHidden {
	position: absolute !important;
	clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
	clip: rect(1px, 1px, 1px, 1px);
	padding:0 !important;
	border:0 !important;
	height: 1px !important;
	width: 1px !important;
	overflow: hidden;
}


.icon {
  margin-left: 10px;
}

.placeholder {
  font-size: 20px;
  font-weight: normal;
  color: gainsboro;
}