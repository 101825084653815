/*Button Styles*/
button {
  outline: 0 none !important;
  cursor: pointer;
  border: 1px solid transparent;
}

.jr-btn {
  position: relative;
  display: inline-block;
  font-size: $btn-font-size;
  font-weight: $btn-font-weight;
  text-transform: capitalize;
  line-height: $btn-line-height;
  @include border-radius($border-radius);
  vertical-align: top;
  cursor: pointer;
  text-align: center;

  &:hover,
  &:focus,
  &:active {
    outline: 0 none;
    text-decoration: none;
    @include box-shadow(none !important);
  }

  .login-content & {
    padding: 10px 35px;
  }
}

.btn {
  &:hover,
  &:focus,
  &:active {
    outline: 0 none;
    text-decoration: none;
    @include box-shadow(none !important);
  }

  .login-content & {
    padding: 10px 35px;
  }
}

.btn,
.btn-info,
.btn-success,
.btn-warning,
.btn-danger,
.btn-light,
.btn-dark,
.btn-primary,
.btn-secondary {
  @extend %btn-no-shadow;
  @include box-shadow(none !important);
}

.show > {
  .btn,
  .btn-info,
  .btn-success,
  .btn-warning,
  .btn-danger,
  .btn-light,
  .btn-dark,
  .btn-primary,
  .btn-secondary {
    &.dropdown-toggle:focus {
      @include box-shadow(none !important);
    }
  }
}

.jr-btn,
.jr-fab-btn,
.jr-flat-btn,
.btn {
  margin-bottom: 6px;
  text-transform: capitalize;

  &:not(:last-child) {
    margin-right: 15px;
  }

  .form-inline & {
    margin-bottom: 0;
    min-height: 35px;
  }

  .navbar & {
    margin-bottom: 0;
  }
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: none !important;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-append > .btn {
  margin-right: 0;
  margin-bottom: 0;
  line-height: inherit;
}

.jr-fab-btn {
  position: relative;
  display: inline-flex;
  @extend %size-50;
  @include border-radius($border-radius-circle);
  @include justify-content(center);
  @include align-items(center);
  background-color: map_get($gray, lighten-3);
  overflow: hidden;

  &:hover,
  &:focus {
    outline: 0 none;
    text-decoration: none;
  }
}

.jr-btn,
.jr-flat-btn {
  padding: $btn-padding;

  & i + span,
  & span + i {
    margin-left: 6px;
  }
}

.jr-flat-btn {
  background-color: transparent;
  color: $body-color;
  &:hover,
  &:focus,
  &:active {
    background-color: map_get($gray, lighten-4);
    color: $body-color;
  }
}

.jr-btn-group {
  margin: 0 -8px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  & .jr-btn,
  & .jr-fab-btn {
    margin: 0 8px 18px;
  }
}

.jr-btn-rounded {
  @include border-radius(50px !important);
}

.jr-btn-lg {
  padding: $btn-padding-lg;
}

.jr-btn-sm {
  padding: $btn-padding-sm;
}

.jr-btn-xs {
  padding: $btn-padding-xs;
}

// Fab Button
.jr-btn-fab-lg {
  @extend %size-60;
}

.jr-btn-fab-sm {
  @extend %size-40;
}

.jr-btn-fab-xs {
  @extend %size-30;
}

.jr-btn-fab-lg,
.jr-btn-lg {
  font-size: $btn-font-size-lg;
  & i {
    font-size: ($btn-font-size-lg + 2px);
  }
}

.jr-btn-fab-sm,
.jr-btn-sm {
  font-size: ($btn-font-size-sm - 2px);
  & i {
    font-size: ($btn-font-size-sm - 2px);
  }
}

.jr-btn-fab-xs,
.jr-btn-xs {
  font-size: $btn-font-size-xs;
  & i {
    font-size: $btn-font-size-xs;
  }
}

.complex-btn-wrapper {
  @include display-flex(flex, row, wrap);
  min-width: 200px;
  width: 100%;
}

.complex-btn {
  position: relative;
  height: 200px;
  overflow: hidden;
  width: 33.333%;
  border: 0 none;

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 100px;
  }
  &:hover {
    z-index: 1;
    & .img-btn-overlay {
      opacity: 0.15;
    }

    & .img-marked {
      opacity: 0;
    }

    & .img-title {
      border: 4px solid currentColor;
    }
  }

  .img-btn {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    @include align-items(center);
    @include justify-content(center);
    color: $white;
  }
  .img-src {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: center 40%;
  }

  .img-btn-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: $black;
    opacity: 0.4;
    @include transition(all 0.5s $transition-ease);
  }

  .img-title {
    position: relative;
    padding: 16px 32px 14px;
  }
  .img-marked {
    height: 3px;
    width: 18px;
    background: $white;
    position: absolute;
    bottom: -2px;
    left: calc(50% - 9px);
    @include transition(all 0.5s $transition-ease);
  }
}

.btn-group-mins {
  margin: 0 -5px;

  & .btn,
  & .jr-btn {
    margin: 0 5px 5px;
  }
}

//buttons in label

.jr-btn.jr-btn-label i {
  background-color: rgba($white, 0.2);
  width: $size-40;
  height: 100%;
  line-height: 42px;
  text-align: center;
}

.jr-btn.jr-btn-label.left i {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.jr-btn.jr-btn-label.right i {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.jr-btn.jr-btn-label.left i + span {
  margin-left: $size-30;
}

.jr-btn.jr-btn-label.right i + span {
  margin-right: $size-30;
  margin-left: 0;
}

.jr-btn.jr-btn-label.jr-btn-lg i {
  line-height: 54px;
}

.jr-btn.jr-btn-label.jr-btn-sm i {
  line-height: 32px;
}

.jr-btn.jr-btn-label.jr-btn-xs i {
  line-height: 24px;
  width: $size-30;
}

//Button function
@each $color_name, $color in $colors_palette {
  @each $color_type, $color_value in $color {
    @if $color_type == "base" {
      .btn-#{$color_name} {
        background-color: $color_value;
        color: $white;
        &:hover,
        &:focus {
          background-color: darken($color_value, 5%);
          color: $white;
        }
      }
    }
  }
}

.jr-btn-shadow {
  @include box-shadow(0 1px 5px 0 rgba($black, 0.2), 0 2px 2px 0 rgba($black, 0.14), 0 3px 1px -2px rgba($black, 0.12) !important);
  &:hover,
  &:focus {
    @include box-shadow(0 1px 5px 0 rgba($black, 0.2), 0 2px 2px 0 rgba($black, 0.14), 0 3px 1px -2px rgba($black, 0.12) !important);
  }

  &:active {
    @include box-shadow(none !important);
  }
}

.jr-btn.disabled,
.jr-btn.disabled {
  pointer-events: none;
  opacity: 0.65;
}

.jr-btn-default {
  background-color: $white;
  color: $body-color;
  border: 1px solid $gray-300;
  &:hover,
  &:focus {
    background-color: darken($white, 5%);
    color: $body-color;
  }
}

.jr-btn-secondary {
  background-color: $secondary;
  color: $white;
  &:hover,
  &:focus {
    background-color: darken($secondary, 5%);
    color: $white;
  }
}

.jr-flat-btn.jr-btn-primary {
  background-color: transparent;
  color: $app-primary;

  &:hover,
  &:focus {
    background-color: rgba($app-primary, 0.12);
    color: $app-primary;
  }
}

.bg-primary {
  & .jr-flat-btn.jr-btn-primary {
    background-color: transparent;
    color: $white;

    &:hover,
    &:focus {
      background-color: rgba($white, 0.12);
      color: $white;
    }
  }
}

.jr-flat-btn.jr-btn-secondary {
  background-color: transparent;
  color: $secondary;

  &:hover,
  &:focus {
    background-color: rgba($secondary, 0.12);
    color: $secondary;
  }
}

.bg-secondary {
  & .jr-flat-btn.jr-btn-secondary {
    background-color: transparent;
    color: $white;

    &:hover,
    &:focus {
      background-color: rgba($white, 0.12);
      color: $white;
    }
  }
}

.jr-btn-hover:hover:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: inline-block;
  background-color: rgba($black, 0.06);
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.action-btn {
  height: $size-40;
  width: $size-40;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  @include align-items(center);
  @include justify-content(center);
  font-size: 24px;
  cursor: pointer;
  vertical-align: middle;
  color: rgba($black, 0.54);

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: rgba($black, 0.64);
  }

  .circle-check & {
    height: 24px;
    width: 24px;
    font-size: 16px;
  }

  .chat-main-header & {
    color: $white;
  }
}

.action-replay .jr-btn-default {
  font-size: 14px;
  padding: 5px 10px;
  border-color: $gray-600 !important;
}

.btn-outline-primary,
.btn-outline-secondary {
  &:focus {
    @include box-shadow(none !important);
  }
}