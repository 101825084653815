@import 'variables.scss';
@import './fonts.css';
@import './helpers.scss';
// Override default variables before the import

button:hover{
  opacity: 0.9;
}
button {
  background-color: white;
}

// i.fa-eraser {
//   margin: 0!important;
// }

.buttonContentStyle {
  background: #FFFFFF;
  mix-blend-mode: normal;/* Button */
  border: 1px solid $primaryColor;
  box-sizing: border-box;
  border-radius: 3px;
}

.custom-tooltip {
  opacity: 1!important;
  box-shadow: 0px 2px 8px rgba(102, 102, 102, 0.1);
}

.custom-tooltip-froala {
  margin-top: 0!important;
  padding: 0 8px!important;
  font-family: Arial,Helvetica,sans-serif;
  box-shadow: 0 3px 6px rgba(0,0,0,.16), 0 2px 2px 1px rgba(0,0,0,.14);
  background: #222;
  color: #fff;
  font-size: 11px!important;
  line-height: 22px;
  z-index: 2003;

  &::after {
    display: none;
  }
}

// Import Bootstrap and its default variables

// react toggle styles
.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  // border: 1px solid $primaryColor;
  border: 1px solid #C8C7CC;
  padding: 0;
  border-radius: 20px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle--checked {
    border: 1px solid $primaryColor;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #ffffff;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  // background-color: #000000;
    // border: 1px solid $primaryColor;

}

.react-toggle--checked .react-toggle-track {
  background-color: $primaryColor;
    border: 1px solid $primaryColor;

}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: $primaryColor;
    border: 1px solid $primaryColor;

}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
    border: 1px solid $primaryColor;

}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;

}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #C8C7CC;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
}

.react-toggle--checked .react-toggle-thumb {
  background-color: #ffffff;
}

progress {
  text-align:center;
  height: 2px;
  width: 100%;
  -webkit-appearance: none;
  border: none;
}

progress:before {
  content: attr(data-label);
  font-size: 0.8em;
  vertical-align: 0
}

progress::-webkit-progress-bar {
  background-color: #efeff4;
}

progress::-webkit-progress-value {
  background-color: $primaryColor;
}

progress::-moz-progress-bar {
  background-color: $primaryColor;
}

.listItemMenu {
  flex:1 45px;
  min-width: 280px;
  max-width: 100%;
  min-height: 45px;
  background-color: #fff;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;

  color: #6A6A6A;
  border-bottom: 1px solid #E9E9E9;
  display: flex;
  justify-content: left;
  padding-left:20px;
  align-items: center;

}

.listItemMenu:hover{
  color: #666;
  z-index: 300;
  // text-decoration-color: red;
  text-decoration: none;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
}

.listItemMenuActive {
  flex:1 45px;
  min-width: 280px;
  max-width: 100%;
  min-height: 45px;
  background-color: #fff;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;

  color: #000;
  border-bottom: 1px solid $primaryColor;
  display: flex;
  justify-content: center;
  padding-left:20px;
  align-items: center;

}

.listItemMenuActive:hover{
  color: #000;
  z-index: 300;
  // text-decoration-color: red;
  text-decoration: none;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
}

.containerMenu {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  margin: 20px;
  flex: 0 1 550px;
  min-width: 300px;
  background:white;
  box-shadow: 0px 4px 15px rgba(156, 156, 156, 0.25);
  border-radius: 3px;
  padding-top: 40px;
  // background-color: blue;
}

.contentList {
  // margin-bottom: 45px;
  display: flex;
  max-height: 80%;
  width: 100%;
  max-width: 400px;
}

.containerMenuWrapper{
  // position: relative;
  display: flex;
  align-items: top;
  height: 100%;
  // min-height: 980px;
}

.menuContainer {
  display: flex;
  flex:1 45px;
  flex-direction: column;
  min-height: 50%;
  overflow-y: auto;
  padding: 0 5px 10px 5px;
}

.fieldStyle {
  flex:1 auto;
  height: 40px;
  border: 1px solid #EFEFF4;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  transition: border-color 0 ease-in-out, box-shadow 0 ease-in-out;
}


@import '~bootstrap/scss/bootstrap.scss';
input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input {outline:none;}

.my-form-control {
  flex:1 auto;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #EFEFF4;
  box-sizing: border-box;
  padding: 0 10px;
}

.my-form-control-toggled {
  flex:1 auto;
  width: 100%;
  height: 40px;
  border: 1px solid #fff;
  box-sizing: border-box;
  background: #FFFFFF;
  box-sizing: border-box;
  padding: 0 10px;
  // background-color: red;
}

.my-form-control:focus {
    // border: 1px solid $primaryColor;
  }

.my-form-control + span , .my-form-control-toggled + span {
  color: red;
  position: relative;
  left:0px;
  bottom: 10px;
}

.notification-dismiss {
  display: none;
}

.labelName {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  font-size: 14px;
  color: #000000;
  min-width: 70px;
}

.my-form-control-disabled {
    flex:1 auto;
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    background: #FFFFFF;
    color: #EFEFF4;
    border: 1px solid #EFEFF4;
    box-sizing: border-box;
    padding: 0 10px;
}

.modal-backdrop {
  opacity: 1.5;

}

.styled__control-is-focused{
  background-color: red;
}

.containerMenuWide {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  margin: 20px;
  flex:1;
  max-width: 1150px;
  min-width: 560px;
  background:white;
  box-shadow: 0px 4px 15px rgba(156, 156, 156, 0.25);
  border-radius: 3px;
  padding: 24px 24px 24px 24px;
}
.containerMenuWideActive {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  margin: 20px;
  flex:1;
  max-width: 560px;
  min-width: 560px;
  background:white;
  box-shadow: 0px 4px 15px rgba(156, 156, 156, 0.25);
  border-radius: 3px;
  padding: 24px;
}

////// Monitor live checkboxe style
//checkbox styel
/* Customize the label (the container) */
.containerCheckbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 22px;
  cursor: pointer;
  // border: 1px solid #EFEFF4;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // background-color: red;
}

/* Hide the browser's default checkbox */
.containerCheckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #EFEFF4;
}

/* On mouse-over, add a grey background color */
.containerCheckbox:hover input ~ .checkmark {
  background-color: #fff;
  border: 1px solid #EFEFF4;
}

/* When the checkbox is checked, add a blue background */
.containerCheckbox input:checked ~ .checkmark {
  background-color: #fff;
  color: $primaryColor;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerCheckbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.containerCheckbox .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid $primaryColor;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.fr-popup {
  z-index: 9999!important;
}
