.root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 10px;
  

  background-color: white;
  box-shadow: 10px 10px 5px #aaaaaa;
  box-shadow: 0px 4px 15px rgba(156, 156, 156, 0.25);
  border-radius: 3px;

  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2000;
  transform: translate(-50%, -50%);
}

.body {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex: 1 1;
  padding: 20px;
  padding-bottom: 0;
}

.text {
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 16px;
  text-align: center;
  color: #000;
  margin-bottom: 0;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 80px;

  button + button {
    margin-left: 10px;
  }
}
