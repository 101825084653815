.container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top:0;
  left:0;
  bottom:0;
  right:0;
  opacity: 0.9;
}

.opacityBack{
  position: absolute;
  background-color: #f6f6f6;
  top:0;
  left:0;
  bottom:0;
  right:0;
}

.spinnerStyle {
  font-size: 36px;
  position: absolute;
  color:black;
  z-index: 2000;
}