@import 'src/variables.scss';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 130px;
  height: 40px;
  margin: 0;
  box-sizing: border-box;
  border-radius: 3px;
}

.button:disabled {
  opacity: 0.5;
  cursor: default;
}

.default {
  color:white;
  mix-blend-mode: normal;
  border: 1px solid $primaryColor;
  background-color: $primaryColor;
}

.invert {
  color: $primaryColor;
  border: 1px solid $primaryColor;
  background-color: white;
}
