@import 'src/variables.scss';

.form {
  justify-content: center;
  align-items: center;
  height: 100%;
  border: 0;
  height: 160px;
  }

.FormStyle{
  display: flex;
  flex-direction: column;
  align-items: center;
}


.title {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: bold;
  font-size: 56px;
  color: #2F2F2F;
  margin-bottom: 24px;
}

.subtitle {
  color: #666666;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  margin-bottom: 22px;
}

.fieldName {
  font-size: 16px;
  color: #333;
  margin: 0 auto;
  display: block;
  width: 100%;
  text-align: left;
  
}

.field{
  margin: 0 auto;
  width: 100%;
  max-width: 420px;
  height: 48px;
  margin: 0;
  margin-bottom: 32px;
  font-size: 14px;
  text-align: left;
  padding-left: 22px;
  color: #8A8A8F;
  background: #FFFFFF;
  border: 1px solid #EFEFF4;
  box-sizing: border-box;
  font-family: 'Nunito Sans', sans-serif;
  
}

.button {
  background-color: $primaryColor;
  border-radius: 2px;
  font-size: 14px;
  margin: 0;
  padding: 10px 75px;
  border-radius: 3px;
  color: #FFFFFF;
}

.buttonDisable {
  background-color: #ec812b8a;
}

.nameAndFieldContainer{
  text-align: center;
  width: fit-content;
  min-width: 350px
}