@import 'src/variables.scss';

.theaterNameForm {
  border-bottom: 1px solid #EFEFF4;
  margin-bottom: 19px;
  margin-right: 24px;
}

.warning {
  display: block;
  margin-top: 10px;
  color: red;
}

.input {
  width: 100%;
  border: none;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;

  &::-webkit-input-placeholder {
    font-size: 20px;
    font-weight: normal;
  }

  &::-moz-placeholder {
    font-size: 20px;
    font-weight: normal;
  }

  &:-ms-input-placeholder {
    font-size: 20px;
    font-weight: normal;
  }

  &:-moz-placeholder {
    font-size: 20px;
    font-weight: normal;
  }
}
