/* Open Sans (Regular & Bold) */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/OpenSans-Regular/OpenSans-Regular.eot');
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
      url('./assets/fonts/OpenSans-Regular/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/OpenSans-Regular/OpenSans-Regular.woff2') format('woff2'),
      url('./assets/fonts/OpenSans-Regular/OpenSans-Regular.woff') format('woff'),
      url('./assets/fonts/OpenSans-Regular/OpenSans-Regular.ttf') format('truetype'),
      url('./assets/fonts/OpenSans-Regular/OpenSans-Regular.svg#Lato') format('svg');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/OpenSans-Bold/OpenSans-Bold.eot');
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
      url('./assets/fonts/OpenSans-Bold/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/OpenSans-Bold/OpenSans-Bold.woff2') format('woff2'),
      url('./assets/fonts/OpenSans-Bold/OpenSans-Bold.woff') format('woff'),
      url('./assets/fonts/OpenSans-Bold/OpenSans-Bold.ttf') format('truetype'),
      url('./assets/fonts/OpenSans-Bold/OpenSans-Bold.svg#Lato') format('svg');
}

/* Segoe UI (Regular) */
@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/SegoeUI/SegoeUI.eot');
  src: local('Segoe UI'), local('SegoeUI'),
      url('./assets/fonts/SegoeUI/SegoeUI.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SegoeUI/SegoeUI.woff2') format('woff2'),
      url('./assets/fonts/SegoeUI/SegoeUI.woff') format('woff'),
      url('./assets/fonts/SegoeUI/SegoeUI.ttf') format('truetype'),
      url('./assets/fonts/SegoeUI/SegoeUI.svg#Lato') format('svg');
}

/* Helvetica Neue (Regular & Bold) */
@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/HelveticaNeue-Medium/HelveticaNeueMedium.eot');
  src: local('Helvetica Neue'), local('HelveticaNeue'),
      url('./assets/fonts/HelveticaNeue-Medium/HelveticaNeueMedium.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/HelveticaNeue-Medium/HelveticaNeueMedium.woff2') format('woff2'),
      url('./assets/fonts/HelveticaNeue-Medium/HelveticaNeueMedium.woff') format('woff'),
      url('./assets/fonts/HelveticaNeue-Medium/HelveticaNeueMedium.ttf') format('truetype'),
      url('./assets/fonts/HelveticaNeue-Medium/HelveticaNeueMedium.svg#Lato') format('svg');
}

/* DIN (Regular & Bold) */
@font-face {
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/DIN-Regular/DIN-Regular.eot');
  src: local('DIN Regular'), local('DIN-Regular'),
      url('./assets/fonts/DIN-Regular/DIN-Regular.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/DIN-Regular/DIN-Regular.woff2') format('woff2'),
      url('./assets/fonts/DIN-Regular/DIN-Regular.woff') format('woff'),
      url('./assets/fonts/DIN-Regular/DIN-Regular.ttf') format('truetype'),
      url('./assets/fonts/DIN-Regular/DIN-Regular.svg#Lato') format('svg');
}

@font-face {
  font-family: 'DIN';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/DIN-Bold/DIN-Bold.eot');
  src: local('DIN Bold'), local('DIN-Bold'),
      url('./assets/fonts/DIN-Bold/DIN-Bold.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/DIN-Bold/DIN-Bold.woff2') format('woff2'),
      url('./assets/fonts/DIN-Bold/DIN-Bold.woff') format('woff'),
      url('./assets/fonts/DIN-Bold/DIN-Bold.ttf') format('truetype'),
      url('./assets/fonts/DIN-Bold/DIN-Bold.svg#Lato') format('svg');
}


/* Montserrat (Regular & Bold) */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Montserrat-Regular/Montserrat-Regular.eot');
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
      url('./assets/fonts/Montserrat-Regular/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Montserrat-Regular/Montserrat-Regular.woff2') format('woff2'),
      url('./assets/fonts/Montserrat-Regular/Montserrat-Regular.woff') format('woff'),
      url('./assets/fonts/Montserrat-Regular/Montserrat-Regular.ttf') format('truetype'),
      url('./assets/fonts/Montserrat-Regular/Montserrat-Regular.svg#Lato') format('svg');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/Montserrat-Bold/Montserrat-Bold.eot');
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
      url('./assets/fonts/Montserrat-Bold/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Montserrat-Bold/Montserrat-Bold.woff2') format('woff2'),
      url('./assets/fonts/Montserrat-Bold/Montserrat-Bold.woff') format('woff'),
      url('./assets/fonts/Montserrat-Bold/Montserrat-Bold.ttf') format('truetype'),
      url('./assets/fonts/Montserrat-Bold/Montserrat-Bold.svg#Lato') format('svg');
}

/* Dosis (Regular & SemiBold) */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 400;
  src: local('Dosis Regular'), local('Dosis-Regular'),
      url('./assets/fonts/Dosis-Regular/Dosis-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 600;
  src: local('Dosis SemiBold'), local('Dosis-SemiBold'),
      url('./assets/fonts/Dosis-SemiBold/Dosis-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Gotham Pro';
  font-style: normal;
  font-weight: 400;
  src:
    url('./assets/fonts/GothamPro-Regular/GothamPro-Regular.woff');
}

@font-face {
  font-family: 'Gotham Pro';
  font-style: normal;
  font-weight: 700;
  src:
    url('./assets/fonts/GothamPro-Bold/GothamPro-Bold.woff');
}
