/*Theme Dark Amber Styles*/
$primary-dark-theme: #38424B !default;
$secondary-dark-theme: lighten($primary-dark-theme, 10%);
$dark-theme-text: #E0E0E0 !default;

$sidebar-dark-theme: $primary-dark-theme !default;
$sidebar-text-dark-theme: $dark-theme-text !default;
$sidebar-darken-dark-theme: darken($primary-dark-theme, 2%);

$top-nav-bg-dark-theme: #252525 !default;

// Links
$link-hover-dark-theme: darken($dark-theme-text, 5%);

//Default Body Style
body.dark-theme {
  color: $dark-theme-text;
  background-color: $primary-dark-theme;
}

.dark-theme .modal {
  color: $primary-dark-theme;
}

.dark-theme .project-list .project-name {
  color: $dark-theme-text;
}

.dark-theme .list-group-item {
  background-color: darken($secondary-dark-theme, 5%);
  color: $dark-theme-text;
  border-color: darken($secondary-dark-theme, 2%);

  &:hover {
    background-color: darken($secondary-dark-theme, 6%);
  }
}

.dark-theme .modal .list-group-item {
  background-color: $white;
  color: $primary-dark-theme;
  border-color: $white;

  &:hover {
    background-color: $white;
  }
}

.dark-theme .quick-menu .icon-btn {
  color: $dark-theme-text;
}

.dark-theme .text-dark {
  color: $dark-theme-text !important;
}

.dark-theme .nav-pill-sales {
  & .nav-link {
    background-color: darken($secondary-dark-theme, 2%);
    color: $dark-theme-text;
    border-color: darken($secondary-dark-theme, 2%);

    &.active {
      background-color: $primary-dark-theme;
      color: $dark-theme-text;
      border-color: $primary-dark-theme;

      &:hover,
      &:focus {
        color: $dark-theme-text;
      }
    }
  }
}

.dark-theme .nav-tabs {
  border-color: $primary-dark-theme;
  background-color: darken($primary-dark-theme, 10%);

  .nav-link {
    border-color: transparent;

    @include hover-focus {
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $dark-theme-text;
    background-color: darken($primary-dark-theme, 10%);
    border-color: darken($primary-dark-theme, 10%);
    border-bottom-color: darken($secondary-dark-theme, 2%);
  }
}

.dark-theme .bg-grey.lighten-4,
.dark-theme .jr-card-header-color {
  background-color: darken($secondary-dark-theme, 2%) !important;
  color: $dark-theme-text;
}

//Default card style
.dark-theme {
  & .card,
  & .jr-card {
    background-color: darken($secondary-dark-theme, 5%);
    border-color: darken($secondary-dark-theme, 2%);
  }

  .card-footer {
    border-color: darken($secondary-dark-theme, 2%);
    background-color: transparent !important;
  }
}

.dark-theme {
  & .sub-heading,
  & .meta-date,
  & .text-muted,
  & .meta-comment,
  & .user-detail .user-description {
    color: darken($dark-theme-text, 20%) !important;
  }
}

//-Base-scss
.dark-theme .right-arrow {
  color: darken($dark-theme-text, 5%);
  &:after {
    color: darken($dark-theme-text, 5%);
  }
}

//
// Alternate buttons
//
@each $color, $value in $theme-colors {
  .dark-theme .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .dark-theme a.btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .dark-theme .btn-outline-#{$color} {
    @if $color == "light" {
      @include button-outline-variant($value, $gray-900);
    } @else {
      @include button-outline-variant($value, $white);
    }
  }
}

@each $color, $value in $theme-colors {
  .dark-theme a.btn-outline-#{$color} {
    @if $color == "light" {
      @include button-outline-variant($value, $gray-900);
    } @else {
      @include button-outline-variant($value, $white);
    }
  }
}

.dark-theme .btn-outline-primary,
.dark-theme a.btn-outline-primary {
  color: darken($primary-dark-theme, 5%);
  border-color: darken($primary-dark-theme, 5%);

  &:focus,
  &:hover,
  &.disabled,
  &:disabled,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $white;
    border-color: darken($primary-dark-theme, 5%);
    background-color: darken($primary-dark-theme, 5%);
  }
}

.dark-theme .btn-outline-secondary,
.dark-theme a.btn-outline-secondary {
  color: $secondary-dark-theme;
  border-color: $secondary-dark-theme;

  &:focus,
  &:hover,
  &.disabled,
  &:disabled,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $white;
    border-color: $secondary-dark-theme;
    background-color: $secondary-dark-theme;
  }
}

//- Bootstrap file Style
.dark-theme {
  & a,
  & a.text-primary {
    color: darken($dark-theme-text, 5%);
    &:focus,
    &:hover {
      color: darken($dark-theme-text, 10%) !important;
    }
  }

  & a.badge-light {
    color: darken($dark-theme-text, 50%);
    &:focus,
    &:hover {
      color: darken($dark-theme-text, 60%) !important;
    }
  }

  & .text-primary {
    color: darken($dark-theme-text, 5%) !important;
  }

  & .page-link {
    color: lighten($primary-dark-theme, 15%);
    &:focus,
    &:hover {
      color: lighten($primary-dark-theme, 10%);
    }
  }

  & .bg-primary,
  & .badge-primary {
    background-color: darken($primary-dark-theme, 5%) !important;
    color: $dark-theme-text !important;
  }

  & .btn-primary,
  & .jr-btn-primary,
  & a.btn-primary,
  & a.jr-btn-primary {
    border-color: $primary-dark-theme;
    background-color: darken($primary-dark-theme, 5%) !important;
    color: $dark-theme-text !important;
    @include box-shadow(none);

    &:hover,
    &:focus,
    &:active,
    &.disabled,
    &:disabled {
      background-color: darken($primary-dark-theme, 5%) !important;
      border-color: darken($primary-dark-theme, 5%) !important;
      color: $link-hover-dark-theme !important;
    }
  }
}

.dark-theme .btn-outline-primary {
  color: $link-hover-dark-theme;
  border-color: $link-hover-dark-theme;

  &:hover,
  &:focus,
  &:active {
    background-color: darken($primary-dark-theme, 5%);
    border-color: darken($primary-dark-theme, 5%);
    color: $link-hover-dark-theme;
  }
}

.dark-theme .jr-flat-btn {
  color: $link-hover-dark-theme;

  &:hover,
  &:focus,
  &:active {
    background-color: map_get($gray, lighten-4);
    color: $body-color;
  }
}

.dark-theme .jr-flat-btn.jr-btn-secondary {
  color: $secondary;

  &:hover,
  &:focus {
    background-color: rgba($secondary, 0.12);
    color: $secondary;
  }
}

.dark-theme .jr-flat-btn.jr-btn-primary {
  color: $link-hover-dark-theme;

  &:hover,
  &:focus {
    background-color: rgba($link-hover-dark-theme, 0.12);
    color: $link-hover-dark-theme;
  }
}

.dark-theme .btn-group,
.dark-theme .btn-group-vertical {
  > .jr-btn,
  > .btn-default {
    &.active {
      background-color: $primary-dark-theme;
      border-color: $primary-dark-theme;
      color: $link-hover-dark-theme;
    }

  }

  > .jr-flat-btn {
    border-color: transparent;
    &.active {
      background-color: transparent;
      color: darken($primary-dark-theme, 20%);

      &:hover,
      &:focus {
        background-color: rgba($primary-dark-theme, 0.5);
        color: darken($primary-dark-theme, 20%);
      }
    }
  }
}

//Secondary
.dark-theme {
  & a.text-secondary {
    color: $secondary-dark-theme;
    &:focus,
    &:hover {
      color: darken($secondary-dark-theme, 10%) !important;
    }
  }

  & .text-secondary {
    color: $secondary-dark-theme !important;
  }

  & .bg-secondary {
    background-color: $secondary-dark-theme !important;
    color: $link-hover-dark-theme !important;
  }

  & .badge-secondary {
    background-color: darken($secondary-dark-theme, 10%) !important;
    color: $link-hover-dark-theme !important;
  }

  & .btn-secondary {
    background-color: $secondary-dark-theme;
    border-color: $secondary-dark-theme;
    color: $link-hover-dark-theme;

    &:hover,
    &:focus,
    &:active {
      background-color: darken($secondary-dark-theme, 10%) !important;
      border-color: darken($secondary-dark-theme, 10%) !important;
      color: $link-hover-dark-theme !important;
    }
  }
}

//flat-btn
.dark-theme .jr-flat-btn {
  background-color: transparent !important;
  color: $link-hover-dark-theme !important;
  &:hover,
  &:focus,
  &:active {
    background-color: map_get($gray, lighten-4) !important;
    color: $body-color !important;
  }
}

.dark-theme .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: $link-hover-dark-theme !important;
  border-color: transparent !important;

  &:hover,
  &:focus {
    background-color: rgba($primary-dark-theme, 0.5) !important;
    color: $link-hover-dark-theme !important;
  }
}

.dark-theme .bg-primary {
  & .jr-flat-btn.jr-btn-primary {
    background-color: transparent !important;
    color: $white !important;
    border-color: transparent !important;

    &:hover,
    &:focus {
      background-color: rgba($white, 0.12) !important;
      color: $white !important;
    }
  }
}

.dark-theme .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: lighten($link-hover-dark-theme, 5%) !important;
  border-color: transparent !important;

  &:hover,
  &:focus {
    background-color: rgba($secondary-dark-theme, 0.5) !important;
    color: lighten($link-hover-dark-theme, 5%) !important;
  }
}

.dark-theme .bg-secondary {
  & .jr-flat-btn.jr-btn-secondary {
    background-color: transparent !important;
    color: $white !important;
    border-color: transparent !important;

    &:hover,
    &:focus {
      background-color: rgba($white, 0.12) !important;
      color: $white !important;
    }
  }
}

//_header.scss
.dark-theme .app-main-header {
  background-color: darken($primary-dark-theme, 5%) !important;

  & .search-bar input {
    background-color: lighten($primary-dark-theme, 5%);

    &:focus {
      background-color: $white;
    }
  }

  & .search-dropdown input {
    @media screen and (max-width: 575px) {
      background-color: $white;
    }
  }
}

.dark-theme .jr-card .app-main-header,
.dark-theme .sidebar-header,
.dark-theme .card-header,
.dark-theme .col-with-divider div[class*="col-"] {
  border-color: darken($secondary-dark-theme, 2%);
}

.dark-theme .app-header .app-main-header {
  border-color: darken($primary-dark-theme, 5%);
}

.dark-theme .app-header {
  @include box-shadow(none);
}

//_footer.scss
.dark-theme .app-footer {
  padding: 0 24px;
  border-color: darken($secondary-dark-theme, 2%);
  background-color: $primary-dark-theme;
  color: $dark-theme-text;

  & a.text-secondary {
    color: $dark-theme-text !important;
    &:hover {
      color: darken($dark-theme-text, 10%) !important;
    }
  }
}

.dark-theme .media-list:not(:last-child) {
  border-color: darken($secondary-dark-theme, 2%);
}

.dark-theme .app-main-header.bg-white {
  color: $white !important;
}

//_right-sidebar.scss
.dark-theme .color-theme-header {
  background-color: $primary-dark-theme;
}

//_sidebar.scss
.dark-theme .side-nav {
  background-color: darken($sidebar-dark-theme, 5%) !important;
  color: $sidebar-text-dark-theme !important;
  border-color: darken($secondary-dark-theme, 2%) !important;

  & .user-profile {
    background-color: $sidebar-darken-dark-theme;
    position: relative;
    z-index: 2;
  }

  & .customizer {
    border-bottom: 0 none;
  }

  & .user-detail {
    & .user-name {
      color: $link-hover-dark-theme;
    }
  }
}

.dark-theme ul.nav-menu li a {
  color: $sidebar-text-dark-theme;
}

.dark-theme ul.nav-menu {
  & .nav-header {
    color: $sidebar-text-dark-theme;
  }
}

.dark-theme ul.nav-menu > li.open > a,
.dark-theme ul.nav-menu > li > a:hover,
.dark-theme ul.nav-menu > li > a:focus {
  background-color: $sidebar-darken-dark-theme;
  color: $link-hover-dark-theme;
}

.dark-theme ul.nav-menu li.menu.open > a {
  border-color: $link-hover-dark-theme;
}

.dark-theme ul.nav-menu li.menu > a:focus:before,
.dark-theme ul.nav-menu li.menu > a:hover:before {
  color: $link-hover-dark-theme;
}

.dark-theme ul.nav-menu li ul {
  background-color: $sidebar-darken-dark-theme;
}

.dark-theme ul.nav-menu li.menu > a:before {
  color: $sidebar-text-dark-theme;
}

.dark-theme ul.nav-menu li.menu.open > a:before,
.dark-theme ul.nav-menu li.menu > a:focus:before,
.dark-theme ul.nav-menu li.menu li > a:hover:before {
  color: $link-hover-dark-theme;
}

.dark-theme ul.nav-menu li.menu .sub-menu li > a:before {
  color: $sidebar-text-dark-theme;
}

.dark-theme ul.nav-menu li.menu.open .sub-menu li > a.active {
  background-color: darken($sidebar-dark-theme, 5%);
  color: $white;
}

.dark-theme ul.nav-menu li.menu .sub-menu li > a:hover,
.dark-theme ul.nav-menu li.menu .sub-menu li > a:focus,
.dark-theme ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.dark-theme ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dark-theme ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: darken($sidebar-dark-theme, 5%);
  color: $white;
}

.dark-theme ul.nav-menu li.menu .sub-menu li > a.active .nav-text {
  background-color: $primary-dark-theme;
}

/*Header top Navbar Styles*/
.dark-theme .navbar-nav {
  & li {
    &:hover > a,
    &:focus > a {
      color: lighten($secondary-dark-theme, 40%);
    }

    & a {
      color: $sidebar-text-color;
    }

    & a:hover,
    & a:focus {
      color: lighten($secondary-dark-theme, 40%);
    }
  }

  & li.nav-item > a {
    color: $white;
  }

  & li.nav-item:hover > a,
  & li.nav-item:focus > a,
  & li.nav-item > a:hover,
  & li.nav-item > a:focus,
  & li.nav-item.active > a {
    color: lighten($secondary-dark-theme, 40%);
  }

  ul.sub-menu {
    background-color: darken($secondary-dark-theme, 5%);
    border-color: darken($secondary-dark-theme, 1%);
    //@include box-shadow(0 1px 4px 0 rgba(0, 0, 0, 0.30));

    & li a.active,
    & li.active > a {
      color: lighten($secondary-dark-theme, 40%);
    }
  }

  & .nav-arrow {
    & > a:before {
      color: $sidebar-text-color;
    }

    & a:hover,
    & a:focus {
      &:before {
        color: lighten($secondary-dark-theme, 40%);
      }
    }

    &:hover > a:before,
    &:focus > a:before,
    &.active > a:before {
      color: lighten($secondary-dark-theme, 40%);
    }
  }
}

.dark-theme .app-top-nav {
  background-color: $top-nav-bg-dark-theme !important;

  & .navbar-nav {
    & li.nav-item > a {
      color: $white;
    }

    & li.nav-item:hover > a,
    & li.nav-item:focus > a,
    & li.nav-item > a:hover,
    & li.nav-item > a:focus,
    & li.nav-item.active > a {
      color: lighten($secondary-dark-theme, 40%);
    }
  }
}

//_calendar.scss
.dark-theme .rbc-event {
  background-color: lighten($primary-dark-theme, 30%);
}

.dark-theme .rbc-event.rbc-selected {
  background-color: darken($primary-dark-theme, 10%);
}

.dark-theme .rbc-slot-selection {
  background-color: rgba($primary-dark-theme, 0.7);
}

.dark-theme .rbc-toolbar button:active,
.dark-theme .rbc-toolbar button.rbc-active {
  background-color: rgba($primary-dark-theme, 0.9);
  border-color: lighten($primary-dark-theme, 40%);
  color: lighten($white, 40%)
}

.dark-theme .rbc-toolbar button:active:hover,
.dark-theme .rbc-toolbar button.rbc-active:hover,
.dark-theme .rbc-toolbar button:active:focus,
.dark-theme .rbc-toolbar button.rbc-active:focus {
  background-color: rgba($primary-dark-theme, 0.9);
  border-color: lighten($primary-dark-theme, 40%);
  color: lighten(white, 20%)
}

.dark-theme .rbc-toolbar button:hover,
.dark-theme .rbc-toolbar button:focus {
  background-color: rgba($primary-dark-theme, 0.9);
  border-color: lighten($primary-dark-theme, 40%);
  color: lighten($white, 40%)
}

.dark-theme .rbc-toolbar button {
  background-color: rgba($primary-dark-theme, 0.9);
  border-color: lighten($primary-dark-theme, 40%);
  color: lighten($primary-dark-theme, 40%)
}

.dark-theme .rbc-show-more {
  background-color: lighten($primary-dark-theme, 30%);
}

.dark-theme .rbc-off-range-bg {
  background-color: lighten($primary-dark-theme, 10%);
}

.dark-theme .rbc-day-slot .rbc-event {
  border-color: $primary-dark-theme;
}

//_chat.scss
.dark-theme .chat-sidenav-title {
  color: $primary-dark-theme;
}

.dark-theme .chat-user-item {
  &.active,
  &:hover {
    background-color: lighten($primary-dark-theme, 45%);
  }
}

//_dashboard.scss
.dark-theme .contact-list {
  & i {
    color: $primary-dark-theme;
  }
}

//_login.scss

.dark-theme .login-content .form-control {
  &:focus {
    border-color: $primary-dark-theme;
  }
}

//_portfolio.scss
.dark-theme .filter-with-bg-color ul li {
  & a {
    border-color: $primary-dark-theme;

    &:hover,
    &:focus,
    &.active {
      background-color: $primary-dark-theme;
    }
  }
}

//_card.scss
.dark-theme .profile-intro {
  & .icon {
    color: lighten($primary-dark-theme, 20%);
  }
}

.dark-theme .social-link {
  & li.active a,
  & li a:hover,
  & li a:focus {
    color: darken($dark-theme-text, 5%);
  }
}

//_tables.scss
.dark-theme .actions {
  color: darken($dark-theme-text, 5%);
}

.dark-theme .table-hover tbody tr:hover {
  background-color: rgba($primary-dark-theme, 0.075);
}

//Border Color

.dark-theme .border-primary {
  border-color: darken($dark-theme-text, 5%) !important;
}

//Breadcrumb
.dark-theme .breadcrumb {
  background-color: $primary-dark-theme;
}

//_typography.scss
.dark-theme .desc-list.even-odd li:nth-child(2n) {
  background-color: $primary-dark-theme;
}

//_timeline.scss
.dark-theme .timeline-item::before {
  border-color: darken($secondary-dark-theme, 2%);
}

.dark-theme .timeline-panel {
  background-color: darken($secondary-dark-theme, 5%);
  border-color: darken($secondary-dark-theme, 2%);

  &:after {
    border-color: transparent darken($secondary-dark-theme, 5%);
  }

  &:before {
    border-color: transparent darken($secondary-dark-theme, 1%);
  }
}

//login.scss
.dark-theme .login-content {
  background-color: darken($secondary-dark-theme, 5%);
  border-color: darken($secondary-dark-theme, 2%);
}

//_app-module.scss
.dark-theme .chat-sidenav-header,
.dark-theme .chat-tabs {
  background-color: darken($primary-dark-theme, 10%);
}

.dark-theme .module-nav {
  & li a {
    color: $gray-500 !important;

    &:hover,
    &:focus,
    &.active {
      color: $white !important;
    }

    &.active {
      border-color: darken($primary-dark-theme, 10%);
    }
  }
}

//Module Style
.dark-theme .module-side {
  background-color: darken($secondary-dark-theme, 5%);
}

.dark-theme .module-box-header,
.dark-theme .app-module-sidenav,
.dark-theme .dropdown-menu {
  background-color: darken($secondary-dark-theme, 5%);
  border-color: darken($secondary-dark-theme, 2%);
  color: $dark-theme-text;
}

.dark-theme .dropdown-item {
  color: $dark-theme-text;

  &:hover,
  &:focus {
    background-color: $primary-dark-theme;
    color: $dark-theme-text;
  }
}

.dark-theme .quick-menu .dropdown-menu {
  & .jr-card-header {
    @include box-shadow(none);
    border-color: darken($secondary-dark-theme, 2%);
  }

  &:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid darken($secondary-dark-theme, 5%);
  }

  &:before {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid darken($secondary-dark-theme, 2%);
  }
}

.dark-theme .module-box-header {
  & .icon-btn {
    color: $dark-theme-text;
  }

  & .search-bar input {
    background-color: $primary-dark-theme;

    &:focus {
      background-color: $white;
    }
  }
}

.dark-theme .chat-sidenav-header .search-bar input {
  background-color: $primary-dark-theme;

  &:focus {
    background-color: $white;
  }
}

.dark-theme .module-box-topbar,
.dark-theme .module-list-item,
.dark-theme .module-side-header,
.dark-theme .chat-main-footer,
.dark-theme .messages-list ul li,
.dark-theme hr,
.dark-theme .chat-sidenav,
.dark-theme .chat-user-item,
.dark-theme .chat-main-header,
.dark-theme .modal-header {
  border-color: darken($secondary-dark-theme, 2%);
}

.dark-theme .module-logo,
.dark-theme .user-nav .quick-menu .user-profile {
  background-color: $primary-dark-theme;
  color: $dark-theme-text;
}

.dark-theme .messages-list ul li .user-name a {
  color: $dark-theme-text;
}

// App Modules

.dark-theme .chat-module-box,
.dark-theme .chat-sidenav-content,
.dark-theme .chat-main-header,
.dark-theme .chat-main-footer,
.dark-theme .chat-textarea,
.dark-theme .module-detail-header,
.dark-theme .module-box-content,
.dark-theme .modal-box-footer {
  background-color: $primary-dark-theme;
}

.dark-theme .modal-box-content {
  background-color: lighten($primary-dark-theme, 5%);
}

.dark-theme .chat-user-item {
  &.active,
  &:hover {
    background-color: darken($primary-dark-theme, 10%);
  }
}

.dark-theme .chat-textarea {
  color: $white;
}

.dark-theme .chat-item {
  padding: 16px;

  & .bubble {
    background-color: lighten($primary-dark-theme, 10%);
    border-color: darken($primary-dark-theme, 4%);

    &:before {
      border-right: 9px solid lighten($primary-dark-theme, 10%);
    }

    &:after {
      border-right: 8px solid lighten($primary-dark-theme, 10%);
    }
  }

  &.flex-row-reverse {
    & .bubble {
      margin-right: 16px;
      margin-left: 0;
      background-color: darken($primary-dark-theme, 10%);
      border-color: lighten($primary-dark-theme, 10%);

      &:before {
        left: auto;
        right: -9px;
        border-left: 9px solid darken($primary-dark-theme, 10%);
        border-right: 0 none;
      }

      &:after {
        left: auto;
        right: -8px;
        border-left: 8px solid darken($primary-dark-theme, 10%);
        border-right: 0 none;
      }
    }
  }
}

.dark-theme .modal-box-footer {
  padding-top: 12px;
}

// login page content

.dark-theme .app-logo-content {
  background-color: $primary-dark-theme;
}

.dark-theme .app-social-block {
  & .social-link button {
    border: solid 1px $primary-dark-theme;
    color: $primary-dark-theme;

    &:hover,
    &:focus {
      color: $white;
      background-color: $primary-dark-theme;
    }
  }
}

.dark-theme .form-checkbox > input + .check,
.dark-theme .form-checkbox > input.sm + .check {
  color: $sidebar-text-color;
}

.dark-theme .rc-drawer-sidebar-dark-theme,
.dark-theme .rc-drawer-pin {
  color: $dark-theme-text;
  background-color: $primary-dark-theme;
}

.dark-theme .rc-drawer-left .rc-drawer-sidebar-dark-theme,
.dark-theme .rc-drawer-right .rc-drawer-sidebar-dark-theme {
  @include display-flex(flex, column, nowrap);
  height: 100vh;
}

.dark-theme .rc-drawer-pin {
  @include display-flex(flex, column, nowrap);
  height: 100%;
  padding: 20px;
}

.dark-theme .rc-drawer-docked .rc-drawer-content {
  border-left: 0 none;
}

.dark-theme .rc-drawer-sidebar {
  background-color: $primary-dark-theme;
}

.dark-theme .rc-collapse {
  background-color: darken($secondary-dark-theme, 5%);
  border-color: darken($secondary-dark-theme, 2%);

  & > .rc-collapse-item {
    border-color: darken($secondary-dark-theme, 2%);

    & > .rc-collapse-header {
      color: $dark-theme-text;
    }

    & > .rc-collapse-header .arrow {
      border-left-color: $dark-theme-text;
    }
  }

  & .rc-collapse-content {
    background-color: darken($secondary-dark-theme, 5%);
    border-color: darken($secondary-dark-theme, 2%);
    color: $dark-theme-text;
  }

  & .rc-collapse-item-disabled > .rc-collapse-header {
    background-color: darken($secondary-dark-theme, 5%);
    color: $dark-theme-text;
  }
}

.dark-theme .rc-time-picker-panel {
  color: $primary-dark-theme;
}

//The link which when clicked opens the collapsable ChatUserList
.dark-theme .Collapsible__trigger {
  background-color: darken($primary-dark-theme, 5%);
}

.dark-theme .Collapsible__contentInner {
  background-color: darken($secondary-dark-theme, 5%);
  border-color: darken($secondary-dark-theme, 2%);
}

.dark-theme .categories-list {
  & li a:focus,
  & li a:hover,
  & li.active a {
    color: darken($dark-theme-text, 25%);
  }
}

.dark-theme .table {
  & th,
  & td {
    border-color: darken($secondary-dark-theme, 2%);
  }
}

.dark-theme .table-unbordered .th-border-b th {
  border-color: darken($secondary-dark-theme, 2%) !important;
}

.dark-theme .ReactTable {
  border-color: darken($secondary-dark-theme, 2%);

  & .rt-thead {
    & .rt-th,
    & .rt-td {
      border-color: darken($secondary-dark-theme, 2%);
    }

    &.-header {
      border-color: darken($secondary-dark-theme, 2%);
    }
  }

  & .rt-table .rt-thead.-headerGroups {
    background-color: darken($secondary-dark-theme, 5%);
    border-color: darken($secondary-dark-theme, 2%);
  }

  &.-striped .rt-tbody .rt-tr-group .rt-tr.-odd,
  &.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background-color: darken($secondary-dark-theme, 2%) !important;
  }

  & .rt-tbody {
    & .rt-tr-group,
    & .rt-td {
      border-color: darken($secondary-dark-theme, 2%);
    }
  }

  & .-pagination {
    border-color: darken($secondary-dark-theme, 2%);

    & .-btn:not([disabled]) {
      background-color: darken($secondary-dark-theme, 8%);
      color: $dark-theme-text;

      &:hover {
        background-color: darken($secondary-dark-theme, 10%);
        color: $dark-theme-text;
      }
    }

    & .-btn[disabled] {
      color: $dark-theme-text;
    }
  }
}

.dark-theme .card-strip,
.dark-theme .contact-item {
  border-color: darken($secondary-dark-theme, 2%);
}
