/*Sidebar Styles*/

.side-nav {
  width: $side-nav-width;
  background-color: $sidebar-bg !important;
  color: $sidebar-text-color !important;
  border-right: solid 1px $gray-200 !important;
  z-index: 1250 !important;

  & .user-profile {
    background-color: $sidebar-bg-darken;
    padding: 13px 20px !important;
  }

  & .user-avatar {
    margin: 0 15px 0 0 !important;
    height: 44px !important;
    width: 44px !important;
    line-height: 44px;
  }

  & .user-detail {
    & .user-name {
      color: $sidebar-hover-color;
      cursor: pointer;
      font-size: $font-size-base;
      font-weight: 400;
      white-space: nowrap;
    }
  }
}

.user-detail {
  & .user-name {
    cursor: pointer;
  }
}

/*Navbar Styles*/
ul.nav-menu {
  position: relative;
  font-size: $font-size-base;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

ul.nav-menu li {
  position: relative;
  margin-bottom: 1px;
}

ul.nav-menu li.menu {
  & + .nav-header {
    margin-top: 40px;
  }
  & .sub-menu {
    display: none;
  }
  &.open > .sub-menu {
    display: block;
  }

  &.open {
    & .menu.active > .sub-menu {
      display: block;
    }
  }
}

ul.nav-menu li a {
  color: $sidebar-text-color;
  display: block;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  text-decoration: none;
  @include border-radius(0);

  & .badge {
    margin-bottom: 0;
    padding: 3px 8px 2px;
  }
}

ul.nav-menu {
  & .nav-header {
    color: $sidebar-text-color;
    padding: 4px 22px;
    font-size: 11px;
    text-transform: uppercase;

    &:first-child {
      margin-top: 30px;
    }
  }
}

ul.nav-menu > li.selected > a,
ul.nav-menu > li.open > a,
ul.nav-menu > li > a:hover,
ul.nav-menu > li > a:focus {
  color: $sidebar-hover-color;
}

ul.nav-menu li.menu > a {
  padding: 16px 20px;
  border-left: 2px solid transparent;
}

ul.nav-menu li.menu.open > a {
  border-color: transparent;
}

ul.nav-menu li.menu > a:focus:before,
ul.nav-menu li.menu > a:hover:before {
  color: $sidebar-hover-color;
}

ul.nav-menu li i {
  margin-right: 12px;
  font-size: 15px;
}

ul.nav-menu li ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
}

ul.nav-menu li ul.sub-menu li > a {
  padding: 7px 20px 7px 56px;
}

ul.nav-menu li ul.sub-menu > li > ul > li > a {
  padding-left: 76px;
}

ul.nav-menu li ul.sub-menu > li > ul > li > ul > li > a {
  padding-left: 96px;
}

ul.nav-menu li ul.sub-menu > li > ul > li > ul > li > ul > li > a {
  padding-left: 116px;
}

ul.nav-menu li.menu > a:before {
  color: $sidebar-text-color;
  content: "\f2fb";
  display: inline-block;
  float: right;
  font: {
    family: 'Material-Design-Iconic-Font';
    size: 15px;
  }
  margin-left: 10px;
  position: relative;
  @include transition(all 300ms ease-in-out);
  font-weight: normal;
}
ul.nav-menu li.menu.no-arrow > a:before {
  display: none;
}

ul.nav-menu li.menu.selected > a:before,
ul.nav-menu li.menu.open > a:before,
ul.nav-menu li.menu > a:focus:before,
ul.nav-menu li.menu li > a:hover:before {
  background-color: transparent;
  color: $sidebar-hover-color;
}

ul.nav-menu li.menu.open > a:before,
ul.nav-menu li.menu.active > a:before {
  @include rotate(90deg);
}

ul.nav-menu li.menu .sub-menu li > a.active .nav-text {
  padding: 3px 7px;
  margin-left: -7px;
  background-color: $sidebar-hover-color;
  color: $white;
  @include border-radius($border-radius);
  display: inline-block;
}

ul.nav-menu li.menu .sub-menu li > a:hover,
ul.nav-menu li.menu .sub-menu li > a:focus,
ul.nav-menu li.menu.open .sub-menu li > a.active:before,
ul.nav-menu li.menu .sub-menu li > a:focus:before,
ul.nav-menu li.menu .sub-menu li > a:hover:before {
  color: $sidebar-hover-color;
}

.app-container.mini-drawer {
  & .side-nav {
    @include transition($transition-base);
    width: $mini-drawer-width;

    & .nav-menu li span.nav-text,
    & .customizer {
      display: none;
    }

    & .user-profile .user-detail {
      display: none !important;
    }

    & .nav-menu .menu.open > .sub-menu {
      display: none !important;
    }

    & .nav-menu li {
      text-align: center;
      i {
        margin-right: 0;
      }

      &.nav-header {
        padding: 4px 5px;
      }
    }

    & ul.nav-menu {
      & li a {
        padding: 16px 20px;
      }
      & ul.sub-menu li a {
        padding-left: 30px;
      }
    }

    & ul.nav-menu > li > a {
      &:before {
        display: none;
      }
    }

    & .mini-logo-img-hover {
      display: none;
    }
  }
}

.app-container.mini-drawer {
  & .side-nav:hover {
    width: $side-nav-width;

    & .nav-menu li span.nav-text {
      display: inline-block !important;
    }

    & .user-profile .user-detail {
      display: flex !important;
    }

    & .nav-menu .menu.open > .sub-menu {
      display: block !important;
    }

    & .customizer {
      display: flex !important;
    }

    & ul.nav-menu {
      & li {
        text-align: left;
        & i {
          margin-right: 16px;
        }

        & a {
          padding: 16px 20px;
        }

        &.nav-header {
          padding: 4px 22px;
        }
      }

      & ul.sub-menu li a {
        padding: 7px 20px 7px 56px;
      }

      & ul.sub-menu > li > ul > li > a {
        padding-left: 76px;
      }

      & ul.sub-menu > li > ul > li > ul > li > a {
        padding-left: 96px;
      }

      & ul.sub-menu > li > ul > li > ul > li > ul > li > a {
        padding-left: 116px;
      }
    }

    & ul.nav-menu > li > a {
      &:before {
        display: block;
      }
    }

    & .mini-logo-img {
      display: none;
    }

    & .mini-logo-img-hover {
      display: block;
    }
  }
}

.customizer {
  padding: 20px 35px;
  & img {
    padding: 5px;
    cursor: pointer;
  }
}

.user-info {
  & ul {
    & li {
      font-size: 15px;
    }
  }
}

.rc-drawer {
  &-left &-sidebar,
  &-right &-sidebar {
    height: 100vh;
  }
}

.rc-drawer-sidebar {
  position: fixed;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;

  .rc-drawer.rc-drawer-left & {
    left: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    z-index: 903;
  }

  .rc-drawer.rc-drawer-right & {
    right: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    z-index: 903;
  }

  .rc-drawer.rc-drawer-top & {
    z-index: 903;
  }

  .rc-drawer.rc-drawer-bottom & {
    z-index: 903;
  }
}

.drawer-container {
  position: relative;
  & .main {
    position: relative;
    padding: 10px;
    box-sizing: border-box;
  }
}

.rc-drawer-overlay {
  z-index: 902;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-out;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  min-width: 100% !important;
}

.app-sidebar {
  &.rc-drawer.rc-drawer-docked {
    position: relative;
  }
}