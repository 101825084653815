@import 'src/variables.scss';

.clientsTable{
  margin: 0 auto;
  margin-top: 30px;
  width: 98%;
}



th, td {
  padding-left: 90px;
  padding-right: 90px;
}

thead{
  
  border-bottom: #e2dfdf .7px solid;
}

th{

  font-size: 15px;
  padding-bottom: 6px;
}



.oddRow{
  background-color: white;
}

.evenRow{
  background-color: #f4f4f4;
}

.PaginationContainer{

 
  width: 100%;
  //margin-top: 10px;
  display: flex;
  flex-direction: row;

  background: white;
  box-shadow: 0px 2px 0px rgba(102, 102, 102, 0.1);
  //box-shadow: 0px 2px 8px rgba(102, 102, 102, 50%);
  padding: 5px;
  padding-bottom: 20px;
  border-radius: 3px;
  
  
}

.outerClientsContainer{
  padding: 20px;
  height: 90%;
  width: 100%;
  max-width: none;

  &__onEdit{
    max-width: 50%;
  }

}

.clientsContainer{
  height: 100%;
  background: white;
  box-shadow: 0px 2px 8px rgba(102, 102, 102, 0.1);
  //box-shadow: 0px 2px 8px rgba(102, 102, 102, 50%);
  padding: 25px;
  border-radius: 3px;
  overflow-y: auto;
  padding-top: 25px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 179px;
  height: 40px;
  background: $primaryColor;
  mix-blend-mode: normal;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  color: white;
  text-decoration: none;
  align-self: flex-end;

  &:hover {
    text-decoration: none;
    color: white;
  }
}

.tableIcon{
  padding: 10px 8px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  cursor: pointer;

  transition: all 0.2s linear;

  &:hover{
    scale: 1.1;
  }
}

.tableIconsContainer{
  display: flex;
  flex-direction: row;
  width: fit-content;
}

.buttonsContainer{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end!important;
  align-items: flex-end!important;
}

.cancelButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 40px;
  background: $primaryColor;
  mix-blend-mode: normal;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  color: red;
  border: 1px solid red;
  text-decoration: none;
  align-self: flex-end;
  margin-right: 5px;
  background-color: white;

  &:hover {
    text-decoration: none;
    color: red;
  }
}