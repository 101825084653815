@import 'src/variables.scss';

.container {
    flex: 1 1 60%;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    background-color: #ffffff;
    padding: 24px;
    box-shadow: 0px 2px 8px rgba(102, 102, 102, 0.1);
    position: relative;
    min-width: 550px;
    margin-top: 20px;
    margin-right: 20px;
    height: 91.5%;

    & > button {
      align-self: center;
      width: 100%;
      max-width: 165px;
    }
  }

  .title{
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 25px;
    border-bottom: 2px solid #5c5c5c;
 
  }

  .closeButton {
    position: absolute;
    top: 28px;
    right: 20px;
    width: 25px;
    height: 25px;
    background-image: url('../../../../../assets/images/icons/close.svg');
    background-size: contain;
    background-color: transparent;
    position: absolute;
    transform: translateY(-50%);
    cursor: pointer;
    padding: 4px;
    z-index: 10;
  }

  .clientsForm {
    border-bottom: 1px solid #EFEFF4;
    padding-bottom: 24px;
    margin-right: 24px;
  }
  
  .warning {
    display: block;
    margin-top: 10px;
    color: red;
  }
  
  .input {
    width: 100%;
    border: none;
    padding: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
  
    &::-webkit-input-placeholder {
      font-size: 20px;
      font-weight: normal;
    }
  
    &::-moz-placeholder {
      font-size: 20px;
      font-weight: normal;
    }
  
    &:-ms-input-placeholder {
      font-size: 20px;
      font-weight: normal;
    }
  
    &:-moz-placeholder {
      font-size: 20px;
      font-weight: normal;
    }
  }
  
  .addClientButton {
    margin-top: 30px!important;
  }


  .cancelButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px;
    background: $primaryColor;
    mix-blend-mode: normal;
    border-radius: 3px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 14px;
    color: #b71c1c;
    border: 1px solid #b71c1c;
    text-decoration: none;
    align-self: flex-end;
    margin-left: 5px;
    background-color: white;
    
  
    &:hover {
      text-decoration: none;
      color: #b71c1c;
     
    }
  }

  .buttonsContainer{
    display:flex;
    flex-direction: row;
    align-items: flex-start;
  }