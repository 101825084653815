/* Slider
   ========================================================================== */
.brand-logo {
  @extend %thumb-equal;
  border: 1px solid $gray-300;
  background-color: $white;
  @include border-radius($border-radius-lg);
  margin-bottom: 20px;
  padding-bottom: 75%;

  & .brand-logo-inner {
    @extend %thumb-cover;
    text-align: center;

    &:before {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }

    & img {
      height: auto;
      max-height: 100%;
      max-width: 100%;
      display: inline-block;
      vertical-align: middle;
      width: auto !important;
    }
  }

  &:hover {
    @include transition(all 0.4s ease-in-out 0.1s);
    background-color: darken($white, 5%);
  }

  @media screen and (max-width: 575px) {
    margin-bottom: 15px;
  }
}

.owl-app-frame {
  &.owl-carousel .owl-item img {
    display: inline-block;
    width: auto;
  }
}

.carousel-item {
  & img {
    max-width: 100%;
  }
}