.root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  user-select: none;
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 329px;
  height: 163px;
  padding: 24px;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}

.text {
  font-size: 16px;
  text-align: center;
  color: #000000;
  margin-bottom: 24px;
}