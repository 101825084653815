@import 'src/variables.scss';

.decline {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid $primaryColor;
  mix-blend-mode: normal;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  color: $primaryColor;
  margin: 0;
  cursor: pointer;
 }

 .confirm {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 40px;
  mix-blend-mode: normal;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  color: #FFFFFF;
  margin: 0;
  background: $primaryColor;
  cursor: pointer;
 }